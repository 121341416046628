import React from "react";
import { AiOutlineBank } from "react-icons/ai";
import { IoShareSocialOutline } from "react-icons/io5";
import { IoMdListBox } from "react-icons/io";
import { motion } from "framer-motion";
import ProcessingActivity from "../Components/ProcessingActivity";
import FailedActivity from "../Components/FailedActivity";
import SuccessActivity from "../Components/SuccessActivity";

function PaymentSuccess() {
  return (
    <div className="">
      {/* <SuccessActivity /> */}
      {/* <FailedActivity /> */}
      <ProcessingActivity />
    </div>
  );
}

export default PaymentSuccess;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { RxCross2 } from "react-icons/rx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GrLogout } from "react-icons/gr";
const base_url = process.env.REACT_APP_BASE_URL;
function Modal({ isOpen, onClose, onOtpSubmit, mobileNumber }) {
  const [otp, setOtp] = useState("");
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    if (otp.length !== 6) {
      Swal.fire({
        icon: "error",
        title: "Invalid OTP",
        text: "OTP should be exactly 6 digits.",
        confirmButtonText: "Okay",
      });
      return;
    }
    try {
      const base_url = process.env.REACT_APP_BASE_URL;
      const url = `${base_url}app/verify_otp.php?number=${mobileNumber}&otp=${otp}`;

      const response = await fetch(url);
      if (!response.ok) {
        Swal.fire({
          icon: "warning",
          title: "Invalid Response",
          text: "There was an issue verifying your OTP. Please try again.",
          confirmButtonText: "Okay",
        });
        return;
      }

      const data = await response.json();

      if (data.status === "Success") {
        localStorage.setItem("key", data.data1);
        localStorage.setItem("name", data.data2);
        localStorage.setItem("number", data.data3);
        localStorage.setItem("refer_code", data.data4);
        onOtpSubmit(otp);
        onClose();
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid OTP",
          text: data.message || "The OTP entered is incorrect.",
          confirmButtonText: "Okay",
        });
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Could not verify OTP. Please try again later.",
        confirmButtonText: "Okay",
      });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 animate-modal">
      <div className="bg-white rounded-lg shadow-lg transform transition-all duration-300 scale-100 opacity-100 animate-modal">
        <div className="flex items-center justify-end p-2">
          <RxCross2 size={25} onClick={onClose} />
        </div>
        <div className="p-5">
          <h2 className="text-lg font-bold mb-4">Enter OTP</h2>
          <p className="mb-4 text-sm text-gray-600">
            OTP has been sent to <strong>{mobileNumber}</strong>
          </p>
          <form onSubmit={handleOtpSubmit}>
            <input type="number" value={otp} onChange={(e) => setOtp(e.target.value)} className="border p-2 mb-4 w-full outline-none rounded" placeholder="Enter OTP" required />
            <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded w-full">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
function Signup() {
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const validateMobileNumber = (number) => {
    const regex = /^[6-9][0-9]{9}$/;
    return regex.test(number);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateMobileNumber(mobileNumber)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Mobile Number",
        text: "Please enter a valid 10-digit mobile number.",
        confirmButtonText: "Okay",
      });
      return;
    }
    var url = `${base_url}app/register.php?name=${name}&number=${mobileNumber}&email=${email}&token=784116`;
    //fetch from server
    try {
      const response = await fetch(url);

      // Check if response is ok
      if (!response.ok) {
        Swal.fire({
          icon: "warning",
          title: "Pending",
          text: "Invalid response from server.",
          confirmButtonText: "Okay",
        });
      }

      const data = await response.json();
      if (data.status == "Success") {
        setIsModalOpen(true);
      } else {
        if (data.suggestion == "registered") {
          Swal.fire({
            icon: "warning",
            title: "Login",
            text: data.message || "Go to login page",
            confirmButtonText: "Login",
          }).then(() => {
            navigate("/");
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: data.message || "An error occurred",
            confirmButtonText: "Oky",
          });
        }
      }
    } catch (error) {
      console.error("Fetch error:", error);
      Swal.fire({
        icon: "error",
        title: "Server Error",
        text: "Could not connect to the server. Please try again later.",
        confirmButtonText: "Okay",
      });
    }
  };

  const handleOtpSubmit = (otp) => {
    console.log("OTP submitted for verification:", otp);
    toast.success("Login Successful! Welcome back!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      onClose: () => navigate("/home"),
    });
  };
  return (
    <div
      className="bg-[#681667] flex items-end md:items-center justify-center h-[100vh] w-full "
      style={{
        backgroundImage: `url('/categories/background.png')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="w-full md:w-[45%] lg:w-[28%]">
        <ToastContainer />
        <div className="bg-white p-5 rounded-t-[35px] md:rounded-[35px] shadow-lg h-[80vh] ">
          <div className="flex items-center justify-center">
            <img src="/loginIcon.png" className="h-[80px] self-center" alt="logo" />
          </div>
          <h2 className="text-[25px] font-bold mb-4 text-center">Sign Up</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="text-[16px] font-bold">Name</label>
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="border p-2 py-2.5 mt-2 w-full outline-none rounded-md" placeholder="Enter Your Name" required />
            </div>
            <div className="mb-4">
              <label className="text-[16px] font-bold">Mobile Number</label>
              <input type="tel" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} className="border p-2 py-2.5 mt-2 w-full outline-none rounded-md" placeholder="Enter Mobile Number" required />
            </div>
            <div className="mb-4">
              <label className="text-[16px] font-bold">Email</label>
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="border p-2 py-2.5 mt-2 w-full outline-none rounded-md" placeholder="Enter Your Email" required />
            </div>
            <button type="submit" className="bg-blue-500 text-white py-2.5 px-4 rounded-md w-full relative">
              Sign Up
              <GrLogout className="absolute top-[13px] left-[33%]" size={20} />
            </button>
          </form>
          <div className="flex mt-3 gap-1 justify-center">
            <h1>Already have an account?</h1>
            <Link to="/" className="text-blue-700">
              Login your account
            </Link>
          </div>
        </div>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onOtpSubmit={handleOtpSubmit} mobileNumber={mobileNumber} />
      </div>
    </div>
  );
}

export default Signup;

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { RxCross2 } from "react-icons/rx";
import { GrLogout } from "react-icons/gr";

function Modal({ isOpen, onClose, onOtpSubmit, mobileNumber }) {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");

  const [otp, setOtp] = useState("");

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    if (otp.length !== 6) {
      Swal.fire({
        icon: "error",
        title: "Invalid OTP",
        text: "OTP should be exactly 6 digits.",
        confirmButtonText: "Okay",
      });
      return;
    }
    try {
      const url = `${base_url}app/verify_otp.php?number=${mobileNumber}&otp=${otp}`;
      const response = await fetch(url);
      if (!response.ok) {
        Swal.fire({
          icon: "warning",
          title: "Invalid Response",
          text: "There was an issue verifying your OTP. Please try again.",
          confirmButtonText: "Okay",
        });
        return;
      }

      const data = await response.json();

      if (data.status === "Success") {
        localStorage.setItem("key", data.data1);
        localStorage.setItem("name", data.data2);
        localStorage.setItem("number", data.data3);
        localStorage.setItem("refer_code", data.data4);
        onOtpSubmit(otp);
        onClose();
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid OTP",
          text: data.message || "The OTP entered is incorrect.",
          confirmButtonText: "Okay",
        });
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Could not verify OTP. Please try again later.",
        confirmButtonText: "Okay",
      });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 animate-modal">
      <div className="bg-white rounded-lg shadow-lg">
        <div className="flex items-center justify-end p-2">
          <RxCross2 size={25} onClick={onClose} />
        </div>
        <div className="p-5">
          <h2 className="text-lg font-bold mb-4">Enter OTP</h2>
          <p className="mb-4 text-sm text-gray-600">
            OTP has been sent to <strong>{mobileNumber}</strong>
          </p>
          <form onSubmit={handleOtpSubmit}>
            <input type="number" value={otp} onChange={(e) => setOtp(e.target.value)} className="border p-2 mb-4 w-full outline-none rounded" placeholder="Enter OTP" required />
            <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded w-full">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

function Login() {
  const [mobileNumber, setMobileNumber] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const key = localStorage.getItem("key");
    const storedNumber = localStorage.getItem("number");

    // Check if key and profile data are available and correct
    if (key && storedNumber) {
      navigate("/home"); // Redirect to home if key and profile data are valid
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const mobileRegex = /^[6-9][0-9]{9}$/;
    if (!mobileRegex.test(mobileNumber)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Number",
        text: "Please enter a valid 10-digit phone number.",
        confirmButtonText: "Okay",
      });
      return;
    }

    const url = `${process.env.REACT_APP_BASE_URL}app/login.php?number=${mobileNumber}&token=784116`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        Swal.fire({
          icon: "warning",
          title: "Pending",
          text: "Invalid response from server.",
          confirmButtonText: "Okay",
        });
      }

      const data = await response.json();
      if (data.status === "Success") {
        setIsModalOpen(true);
      } else {
        if (data.suggestion === "register") {
          Swal.fire({
            icon: "warning",
            title: "Register",
            text: data.message || "Go to register page",
            confirmButtonText: "Register",
          }).then(() => {
            navigate("/signup");
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: data.message || "An error occurred",
            confirmButtonText: "Okay",
          });
        }
      }
    } catch (error) {
      console.error("Fetch error:", error);
      Swal.fire({
        icon: "error",
        title: "Server Error",
        text: "Could not connect to the server. Please try again later.",
        confirmButtonText: "Okay",
      });
    }
  };

  const handleOtpSubmit = (otp) => {
    toast.success("Login Successful! Welcome back!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      onClose: () => navigate("/home"),
    });
  };

  return (
    <div
      className="bg-[#681667] flex items-end md:items-center h-[100vh] justify-center w-full  md:pt-0"
      style={{
        backgroundImage: `url('/categories/background.png')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="w-full md:w-[45%] lg:w-[28%]">
        <ToastContainer />
        <div className="bg-white p-5 h-[80vh] rounded-t-[35px] md:rounded-[35px] shadow-lg ">
          <div className="flex items-center justify-center">
            <img src="/loginIcon.png" className="h-[80px] self-center" alt="logo" />
          </div>
          <form onSubmit={handleSubmit}>
            <h2 className="text-[25px] font-bold mb-4 text-center">Login</h2>
            <div>
              <label className="text-[16px] font-bold">Phone Number</label>
              <input type="tel" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} className="border p-2 py-2.5 mb-4 w-full mt-2 outline-none rounded-md" placeholder="Enter Phone Number" maxLength={10} required />
            </div>
            <button type="submit" className="bg-blue-500 text-white py-2.5 px-4 rounded-md w-full relative">
              Send OTP
              <GrLogout className="absolute top-[13px] left-[33%] md:left-[28%] lg:left-[30%]" size={20} />
            </button>
          </form>
          <div className="flex mt-3 gap-1 items-center justify-center">
            <h1>Don't have an account? </h1>
            <Link to="/signup" className="text-blue-700">
              Create your account
            </Link>
          </div>
        </div>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onOtpSubmit={handleOtpSubmit} mobileNumber={mobileNumber} />
      </div>
    </div>
  );
}

export default Login;

// Error.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

function Error({ title, description }) {
  return (
    <div className="h-screen w-full flex flex-col items-center justify-center bg-red-500 text-white p-4">
      <FontAwesomeIcon icon={faExclamationTriangle} className="text-6xl mb-4" />
      <h1 className="text-[20px] font-bold mb-2">{title}</h1>
      <p className="text-center max-w-md leading-tight text-[14px]">{description}</p>
    </div>
  );
}

export default Error;

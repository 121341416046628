import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useSearchParams } from "react-router-dom";
import Loading from "../Components/Loading";
import Error from "../Components/Error";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Operator = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");

  const [margins, setMargins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [searchParams] = useSearchParams();
  const category = searchParams.get("category");
  const sub_category = searchParams.get("sub_category");

  useEffect(() => {
    // Fetch data from server
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`${base_url}app/operator.php?key=${key}&category=${category}&sub_category=${sub_category}`);
        const data = await response.json();
        if (data.status === "Success") {
          setMargins(data.data1);
        } else {
          setError("Failed to fetch data.");
        }
      } catch (error) {
        // toast.error("Error fetching margin data:", error);
        setError("Error fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [category]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-slate-200 h-screen flex items-center justify-center">
        <Error title="An error occurred" description="Please try refreshing the page or go back. If the problem persists, contact support." />
      </div>
    );
  }

  return (
    <div className="bg-slate-200 min-h-screen select-none pb-[6px]">
      <div className="h-[60px] w-full bg-[#681667] flex items-center px-4 gap-5 text-white">
        <Link to="/home">
          <FaArrowLeft size={20} />
        </Link>
        <h1 className="font-bold">Operator</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-2 lg:gap-3 md:px-2 lg:px-3 md:pt-2  lg:pt-3 pb-3">
        {margins.map((item) => (
          <Link to={`/operatorform?operator_id=${item.operator_id}`} key={item.operator_id} className="bg-white mt-[6px] md:mt-0 flex items-center justify-between px-3 py-3 md:py-4 lg:py-5 select-none gap-4 md:rounded">
            <div className="flex items-center gap-5 w-full">
              <img src={base_url + item.operator_logo} alt={`${item.biller_name} logo`} className="h-[35px] w-[35px]" />
              <div>
                <h1 className="font-bold text-[15px]">{item.biller_name}</h1>
                <h2 className="text-[12px]">{item.biller_category}</h2>
              </div>
            </div>
            <div className="w-[120px]">
              <h2 className="bg-zinc-200 px-2 py-1 rounded text-[12px] flex items-center justify-center">
                {item.commission} {item.commission_type === "%" ? "%" : "Flat"}
              </h2>
            </div>
          </Link>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Operator;

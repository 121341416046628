import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const AddMoney = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");

  const [amount, setAmount] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${base_url}app/payment.php?key=${key}&amount=${amount}`);
      const data = await response.json();

      if (data.status === "Success") {
        alert(data.data1);
      } else {
        console.error("Failed to fetch profile data:", data.message);
        // setError(true);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
      // setError(true);
    }
  };

  return (
    <motion.div className="" initial={{ x: 400 }} animate={{ x: 0 }} transition={{ ease: "easeOut", duration: 0.2 }}>
      <div className="h-[50px] w-full bg-[#681667] flex items-center px-4 gap-5 text-white">
        <Link to="/home">
          <FaArrowLeft size={20} />
        </Link>
        <h1 className="font-bold">Add Money</h1>
      </div>
      <form onSubmit={handleSubmit} className="flex flex-col h-full p-3 py-[30px] gap-6">
        <div className="space-y-1">
          <div className="flex relative">
            <input type="number" placeholder="Amount" value={amount} onChange={(e) => setAmount(Number(e.target.value))} className="w-full p-2 py-[10px] border-2 border-[#681667] rounded-md mb-2 ps-8" />
            <span className="text-[22px] font-semibold absolute top-2 left-5 opacity-70">₹</span>
          </div>
          <div className="flex items-center px-1 justify-between">
            <button type="button" onClick={() => setAmount(500)} className="border-2 border-[#681667] rounded px-3 text-[14px] flex items-center justify-center font-semibold bg-slate-200">
              +₹500
            </button>
            <button type="button" onClick={() => setAmount(1000)} className="border-2 border-[#681667] rounded px-3 text-[14px] flex items-center justify-center font-semibold bg-slate-200">
              +₹1000
            </button>
            <button type="button" onClick={() => setAmount(3000)} className="border-2 border-[#681667] rounded px-3 text-[14px] flex items-center justify-center font-semibold bg-slate-200">
              +₹3000
            </button>
            <button type="button" onClick={() => setAmount(5000)} className="border-2 border-[#681667] rounded px-3 text-[14px] flex items-center justify-center font-semibold bg-slate-200">
              +₹5000
            </button>
          </div>
        </div>
        <div>
          <button type="submit" className="bg-[#681667] text-white w-full py-[10px] mt-2 rounded-lg">
            PAY NOW
          </button>
        </div>
      </form>
      <div className="p-4">
        <div className="bg-slate-200 p-2 rounded-md select-none">
          <h1 className="text-[14px]">Once a payment is successfully processed and added to your account, it cannot be reversed or refunded. For more detailed information, please refer to our rufund policy.</h1>
        </div>
      </div>
    </motion.div>
  );
};

export default AddMoney;

import React from "react";
import { MdReport } from "react-icons/md";

function RecordNotFound() {
  return (
    <div className="h-screen w-full bg-slate-200 flex flex-col items-center justify-center px-3">
      <MdReport size={40} />
      <h1 className="text-[17px] font-bold pt-2 pb-1">No record found</h1>
      <p className="text-[15px] text-center">No active operator found to the such category. Please try after sometimes or may contact support team.</p>
    </div>
  );
}

export default RecordNotFound;

import React, { useState } from "react";
import { FaAddressBook, FaArrowLeft } from "react-icons/fa";
import { PiDotsNineBold } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Prepaid = () => {
  const navigate = useNavigate();
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");

  const [mobileNumber, setMobileNumber] = useState("");
  const [amount, setAmount] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${base_url}app/prepaid.php?key=${key}&amount=${amount}`);
      const data = await response.json();

      if (data.status === "Success") {
        alert(data.data1);
      } else {
        toast.error("Failed to fetch profile data:", data.message);
      }
    } catch (error) {
      toast.error("Error fetching profile data:", error);
    }
  };
  function continueButton() {
    if (/^[6789]\d{9}$/.test(mobileNumber)) {
      navigate(`/rechargeplans?number=${mobileNumber}`);
    } else {
      toast.error("Enter valid number.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
  }

  return (
    <motion.div initial={{ x: 400 }} animate={{ x: 0 }} transition={{ ease: "easeOut", duration: 0.2 }} className="h-screen flex flex-col  bg-slate-200 justify-start">
      <div className="h-[60px] w-full bg-[#681667] flex items-center px-4 gap-5 text-white">
        <Link to="/home">
          <FaArrowLeft size={20} />
        </Link>
        <h1 className="font-bold">Prepaid</h1>
      </div>
      <form onSubmit={handleSubmit} className="flex flex-col  p-2 py-[30px]">
        <div className="space-y-4">
          <div className="relative flex items-center">
            <input type="tel" placeholder="Mobile Number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} className="w-full py-[12px] px-[35px] rounded-md outline-none border-2 border-[#681667]" required />
            <FaAddressBook className="absolute right-2 text-[#681667] cursor-pointer" size={20} />
            <PiDotsNineBold className="absolute left-2 text-[#681667] cursor-pointer" size={20} />
          </div>
        </div>
        <div className="mt-6">
          <button type="button" onClick={continueButton} className="bg-[#681667] text-white w-full py-[12px] rounded-md select-none">
            Continue
          </button>
        </div>
      </form>
      <div className="px-4">
        <div className="bg-white p-2 rounded-md select-none">
          <h1 className="text-[14px]">Once a payment is successfully processed and added to your account, it cannot be reversed or refunded. For more detailed information, please refer to our refund policy.</h1>
        </div>
      </div>
      <ToastContainer />
    </motion.div>
  );
};

export default Prepaid;

import React, { useState, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { Link, useSearchParams } from "react-router-dom";
import Loading from "../Components/Loading";
import LoadingData from "../Components/LoadingData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdOutlineShareLocation } from "react-icons/md";
import { FaIndianRupeeSign } from "react-icons/fa6";

function RechargePlans() {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");
  const [searchParams] = useSearchParams();
  const number = searchParams.get("number");

  const [operator, setOperator] = useState("");
  const [region, setRegion] = useState("");
  const [operatorCode, setOperatorCode] = useState("");
  const [regionCode, setRegionCode] = useState("");
  const [plansData, setPlansData] = useState({});
  const [activeTab, setActiveTab] = useState(""); // To track active tab
  const [filteredPlans, setFilteredPlans] = useState([]); // For filtering plans
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState("");
  const [selectedCircle, setSelectedCircle] = useState("");
  const [operatorIcon, setOperatorIcon] = useState("");
  const [modal, setModal] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${base_url}app/fetch_op_state.php?key=${key}&number=${number}`);
        const data = await response.json();

        if (data.status === "Success") {
          setOperator(data.data2);
          setRegion(data.data1);
          setOperatorCode(data.data4);
          setRegionCode(data.data3);
          fetchPlans(data.data3, data.data4);
        } else {
          toast.error("Failed to fetch data:", data.message);
        }
      } catch (error) {
        toast.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [key, number]);

  const fetchPlans = async (regionCode, operatorCode) => {
    setLoadingData(true);
    try {
      const response = await fetch(`${base_url}app/plans.php?key=${key}&circle_code=${regionCode}&operator_id=${operatorCode}`);
      const data = await response.json();
      if (data.status === "Success") {
        setOperatorIcon(data.data2);
        setPlansData(data.data1.records); // Set the 'records' data to state
        setActiveTab(Object.keys(data.data1.records)[0]); // Set the first tab as active
      } else {
        toast.error(data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
      });
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    if (activeTab) {
      setFilteredPlans(plansData[activeTab] || []);
    }
  }, [activeTab, plansData]);

  const handlePlanClick = (amount) => {
    setSelectedAmount(amount);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedAmount(0);
  };

  const handleSaveSelection = () => {
    setModal(false);
  };
  const handleEditModal = () => {
    setModal(true);
  };

  const handleCloseEditModal = () => {
    setModal(false);
  };

  return (
    <div className="bg-slate-200 min-h-screen">
      {loading ? (
        <Loading />
      ) : (
        <div className="text-white bg-slate-200 ">
          {/* Header */}
          <div className="bg-[#681667] p-4 py-10">
            <header className="flex items-center gap-4">
              <Link to="/prepaid">
                <FaArrowLeft size={20} />
              </Link>
              <button className="text-[20px] font-semibold">Form fills</button>
            </header>
            <div className="mt-4 px-4 flex items-center justify-between">
              <div className="space-y-1">
                <h2 className="text-xl font-bold">+91 {number}</h2>
                <div className="flex items-center gap-2">
                  <p className="text-sm">{operator ? `${operator} - ${region}` : "Loading operator and region..."}</p>
                  <MdEdit size={18} onClick={handleEditModal} />
                </div>
              </div>
              <img src={`${base_url}${operatorIcon}`} className="h-[50px] w-[50px] rounded-full bg-white" />
            </div>
          </div>

          {/* Search Input */}
          <div className="px-4 py-5 bg-slate-200">
            <div className="relative">
              <span className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                <svg className="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-4.35-4.35M16.65 10a6.65 6.65 0 11-13.3 0 6.65 6.65 0 0113.3 0z" />
                </svg>
              </span>
              <input type="number" placeholder="Search amount" className="w-full pl-10 pr-4 py-3 text-black bg-white rounded-md border border-[#681667] focus:outline-none focus:ring-2 focus:ring-[#681667]" />
            </div>
          </div>
          {modal && (
            <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center" onClick={handleCloseEditModal}>
              <div className="bg-white p-4 rounded-lg w-full max-w-[400px] h-full" onClick={(e) => e.stopPropagation()}>
                <div className="flex justify-end items-center mb-4">
                  <button onClick={handleCloseEditModal} className="text-gray-500 text-xl">
                    <RxCross2 />
                  </button>
                </div>
                <div className="space-y-4">
                  <div>
                    <label htmlFor="operator" className="block text-sm font-semibold text-black pb-1">
                      Select Operator
                    </label>
                    <select id="operator" value={selectedOperator} onChange={(e) => setSelectedOperator(e.target.value)} className="w-full px-4 py-3 border-2 border-[#681667] rounded-md outline-none text-black">
                      <option value="" disabled>
                        Select an operator
                      </option>
                      <option value="airtel">Airtel</option>
                      <option value="jio">Jio</option>
                      <option value="vi">Vi</option>
                      <option value="bsnl">BSNL</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="circle" className="block text-sm font-semibold text-black pb-1">
                      Select Circle
                    </label>
                    <select id="circle" value={selectedCircle} onChange={(e) => setSelectedCircle(e.target.value)} className="w-full px-4 py-3 border-2 border-[#681667] rounded-md outline-none text-black">
                      <option value="" disabled>
                        Select your circle
                      </option>
                      <option value="andhraPradesh">Andhra Pradesh</option>
                      <option value="assam">Assam</option>
                      <option value="westBengal">West Bengal</option>
                      <option value="biharAndJharkhand">Bihar & Jharkhand</option>
                      <option value="chennai">Chennai</option>
                      <option value="delhiAndNCR">Delhi & NCR</option>
                      <option value="gujarat">Gujarat</option>
                      <option value="haryana">Haryana</option>
                      <option value="himachalPradesh">Himachal Pradesh</option>
                      <option value="jammuAndKashmir">Jammu & Kashmir</option>
                      <option value="karnataka">Karnataka</option>
                      <option value="kerala">Kerala</option>
                      <option value="Kolkata">Kolkata</option>
                      <option value="maharashtraAndGoa">Maharashtra & Goa (except Mumbari)</option>
                      <option value="mpAndChhattisgarh">MP & Chhattisgarh</option>
                      <option value="mumbai">Mumbai</option>
                      <option value="northeast">Northeast</option>
                      <option value="odisha">Odisha</option>
                    </select>
                  </div>
                </div>
                <button onClick={handleSaveSelection} className="mt-4 w-full py-3 bg-[#681667] text-white rounded-md">
                  Save Changes
                </button>
              </div>
            </div>
          )}
          {/* Tabs */}
          <div className="flex py-2 w-full overflow-x-scroll px-3 no-scrollbar">
            {Object.keys(plansData).map((tab) => (
              <button key={tab} className={`text-black py-1.5 px-4 rounded-md text-nowrap ${activeTab === tab ? "bg-[#681667] text-white" : "bg-transparent"}`} onClick={() => setActiveTab(tab)}>
                {tab}
              </button>
            ))}
          </div>

          {/* Plans */}
        </div>
      )}

      {/* Plan Details Modal */}
      {isModalOpen && selectedAmount && (
        <div className="">
          <div className="bg-white p-6 rounded-lg w-full max-w-[400px]" onClick={(e) => e.stopPropagation()}>
            <div className="flex justify-between items-center">
              <h3 className="text-xl font-semibold">{selectedAmount}</h3>
              <button onClick={handleCloseModal} className="text-gray-500 text-xl">
                <RxCross2 />
              </button>
            </div>
            <p className="text-gray-700 mt-4">{selectedAmount}</p>
            <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-end" onClick={handleCloseModal}>
              <div className="bg-white p-4 rounded-t-lg w-full max-w-[400px] " onClick={(e) => e.stopPropagation()}>
                <div className="flex justify-end items-center mb-4">
                  <button onClick={handleCloseModal} className="text-gray-500 text-xl">
                    <RxCross2 />
                  </button>
                </div>
                <div className="space-y-4">
                  <div className="flex items-center gap-2">
                    <input type="text" placeholder="Enter value 1" className="w-full px-4 py-3 border-2 border-[#681667] rounded-md outline-none" readOnly value={operator} />
                    <input type="text" placeholder="Enter value 2" className="w-full px-4 py-3 border-2 border-[#681667] rounded-md outline-none" readOnly value={number} />
                  </div>
                  <div className="flex items-center relative">
                    <input type="text" placeholder="Enter value 3" className="w-full px-4 py-3 border-2 border-[#681667] rounded-md outline-none pr-10" readOnly value={region} />
                    <MdOutlineShareLocation size={28} className="absolute right-3 text-gray-400" />
                  </div>
                  <div className="flex items-center relative">
                    <input type="text" placeholder="Enter value 4" className="w-full px-4 py-3 border-2 border-[#681667] rounded-md outline-none pr-10" readOnly value={selectedAmount} />
                    <FaIndianRupeeSign size={25} className="absolute right-3 text-gray-400" />
                  </div>
                  <button className="w-full bg-[#681667] text-white py-3 rounded-md mt-2">PAY NOW</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="">
        {loadingData ? (
          <LoadingData />
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 bg-slate-200 pt-2 px-3 pb-4">
            {filteredPlans.map((plan, index) => (
              <div key={index} className="px-4 py-2 bg-white shadow-md rounded-md cursor-pointer" onClick={() => handlePlanClick(plan.amount)}>
                <div className="flex items-center justify-between border-b-2 pb-1">
                  <h1 className="text-[#681667] font-bold text-[13px]">
                    ₹<span>{plan.amount}</span>
                  </h1>
                  <div>
                    <h2 className="text-black text-center text-[14px]">Data</h2>
                    <h2 className="text-[#681667] font-bold text-[13px]">{plan.Data}</h2>
                  </div>
                  <div>
                    <h2 className="text-black text-[14px]">Validity</h2>
                    <h2 className="text-[#681667] font-bold text-[13px]">{plan.Validity}</h2>
                  </div>
                </div>
                <p className="text-[13px] text-gray-500">{plan.planDescription}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      <ToastContainer />
    </div>
  );
}

export default RechargePlans;

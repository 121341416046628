import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCheckCircle, faTimesCircle, faHourglassHalf, faEye } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import Error from "../Components/Error"; // Import your Error component
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ReportCard = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");
  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(false);
      try {
        const response = await fetch(`${base_url}app/report.php?key=${key}`);
        const data = await response.json();
        if (data.status === "Success") {
          setReport(data.data1);
        } else {
          throw new Error("API returned an unsuccessful status");
        }
      } catch (error) {
        toast.error("Error fetching report data:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [base_url, key]);

  return (
    <div className="pb-2 bg-slate-200 min-h-screen">
      <div className="relative py-2 bg-[#681667] outline-none">
        <FontAwesomeIcon icon={faSearch} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white cursor-pointer" />
        <input type="search" placeholder="Search" className="py-2 pl-8 pr-4 w-full bg-[#681667] text-white outline-none" />
      </div>

      {loading ? (
        <Loading />
      ) : error ? (
        <Error title={"Something went wrong"} description={"API fetching error"} />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-3 lg:px-4 pt-2.5 lg:pt-4 gap-1 lg:gap-3">
          {report.map((item) => (
            <div key={item.transactionId} className="bg-white rounded-lg shadow-md p-3 mb-1 border border-gray-200 ">
              <div className="flex items-center justify-between mb-2">
                <div className="text-gray-700 font-bold">
                  {item.category} | {item.number}
                </div>
                <img src={base_url + item.operator_logo} alt="Operator Logo" height={25} width={25} />
              </div>
              <div className="text-sm text-gray-600 mb-1.5">Amount: {item.amount}</div>
              <div className="text-sm text-gray-600 mb-1.5">Time: {item.createAt}</div>
              <div className="text-sm text-gray-600 mb-1.5">Tnx Id: {item.transactionId}</div>
              <div className="flex space-x-4">
                {item.status === "Success" ? (
                  <div className="flex items-center justify-center w-1/2 bg-green-500 text-white font-semibold py-[5px] rounded-full gap-2 select-none">
                    <FontAwesomeIcon icon={faCheckCircle} className="text-white" size="xl" />
                    <p>Success</p>
                  </div>
                ) : item.status === "Failed" ? (
                  <div className="flex items-center justify-center w-1/2 bg-red-500 text-white font-semibold py-[5px] rounded-full gap-2 select-none">
                    <FontAwesomeIcon icon={faTimesCircle} className="text-white" size="xl" />
                    <p>Failed</p>
                  </div>
                ) : (
                  <div className="flex items-center justify-center w-1/2 bg-yellow-500 text-white font-semibold py-[5px] rounded-full gap-2 select-none">
                    <FontAwesomeIcon icon={faHourglassHalf} className="text-white" size="lg" />
                    <p>Pending</p>
                  </div>
                )}
                <Link to={`/transaction_status?order_id=${item.order_id}`} className="flex items-center justify-center w-1/2 bg-cyan-600 text-white font-semibold py-[5px] rounded-full hover:bg-blue-600 transition-all duration-150 gap-2 select-none">
                  <FontAwesomeIcon icon={faEye} className="text-white" style={{ fontSize: 22 }} />
                  <p>View</p>
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default ReportCard;

import React from "react";
import { AiOutlineBank } from "react-icons/ai";
import { IoShareSocialOutline } from "react-icons/io5";
import { IoMdListBox } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

function SuccessActivity({ message }) {
  const navigate = useNavigate();

  const handleBackNavigation = () => {
    navigate(-1);
  };

  return (
    <div className="bg-[#007e24] h-screen w-full flex flex-col items-center justify-center px-4">
      {/* Payment Success Header */}
      <div className="border-b-2 w-full pb-4">
        <div className="flex flex-col items-center ">
          <div className="bg-white p-3 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-20 h-20 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <circle cx="12" cy="12" r="10" className="stroke-current fill-current" />
              <path d="M9 12l2 2 4-4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="stroke-white" />
            </svg>
          </div>
          <h1 className="mt-4 text-xl font-bold text-white">Transaction is success</h1>
          <p className="text-sm mt-2 text-white">UTR {message}</p>
        </div>
      </div>
      <div className="pt-5 flex items-center gap-4">
        <button className="bg-blue-600 text-white px-5 py-2 rounded">VIEW RECEIPT</button>
        <button onClick={handleBackNavigation} className="bg-red-600 text-white px-5 py-2 rounded">
          CLOSE
        </button>
      </div>
    </div>
  );
}

export default SuccessActivity;

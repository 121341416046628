import React, { useState } from "react";
import { FaAddressBook, FaArrowLeft, FaSearch } from "react-icons/fa";
import { PiDotsNineBold } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RecordNotFound from "../Components/RecordNotFound";

const SelectOperator = () => {
  return (
    // <div className="h-screen flex flex-col bg-slate-200 justify-start">
    //   <div className="h-[60px] w-full bg-[#681667] flex items-center px-4 gap-5 text-white">
    //     <Link to="/home">
    //       <FaArrowLeft size={20} />
    //     </Link>
    //     <h1 className="font-bold">Select Biller</h1>
    //   </div>
    //   <div className="flex flex-col p-2 py-3">
    //     <div className="space-y-4">
    //       <div className="relative flex items-center">
    //         <input type="tel" placeholder="Mobile Number" className="w-full py-[12px] px-2 pr-8 rounded-md outline-none border-2 border-[#681667]" required />
    //         <FaSearch className="absolute right-2  cursor-pointer" size={20} />
    //       </div>
    //     </div>
    //   </div>

    //   <ToastContainer />
    // </div>
    <div>
      <RecordNotFound />
    </div>
  );
};

export default SelectOperator;

import React from "react";
import { CiMobile3, CiMobile4 } from "react-icons/ci";
import { FaArrowLeft, FaRegHospital } from "react-icons/fa";
import { FiTv } from "react-icons/fi";
import { LiaHandsSolid } from "react-icons/lia";
import { MdLiveTv } from "react-icons/md";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { Link } from "react-router-dom";

const SeeMore = () => {
  return (
    <div className="bg-slate-200 min-h-screen">
      {/* Header */}
      <div className="bg-[#681667] text-white flex items-center p-3 py-4">
        <Link to="/home">
          <FaArrowLeft size={20} />
        </Link>
        <h1 className="text-lg font-semibold mx-3">Bill Payments</h1>
      </div>

      {/* Categories */}
      <div className="p-2.5 space-y-6 pb-4">
        <div>
          <h2 className="text-[#681667] font-bold mb-2 text-[16px]">Mobile Recharge & Others</h2>
          <div className=" w-full grid grid-cols-4 gap-2 select-none rounded">
            <Link to="/Prepaid" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <CiMobile3 size={22} />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Mobile Recharge</h1>
            </Link>
            <Link to="/operator?category=dth" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <FiTv size={22} />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">DTH Recharge</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/prepaid-meter.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Prepaid Meter</h1>
            </Link>
            <Link to="/ComingSoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <MdLiveTv size={22} />
              <h1 className="text-[12px] font-semibold">Cable TV</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/fastag.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Fastag</h1>
            </Link>
            <Link to="/ComingSoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/ncmc.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">NCMC</h1>
            </Link>
            <Link to="/ComingSoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/ncmc.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">NPS</h1>
            </Link>
          </div>
        </div>

        {/* Utilities */}
        <div>
          <h2 className="text-[#681667] font-bold mb-2 text-[16px]">Utilities</h2>
          <div className="w-full grid grid-cols-4 gap-2  select-none rounded">
            <Link to="/operator?category=Postpaid" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <CiMobile4 size={25} />
              <h1 className="text-[12px] font-semibold ">Postpaid</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/gas-pump.png" className="h-[28px] translate-x-1" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Piped Gas</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/waterbill.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold">Water Bill</h1>
            </Link>
            <Link to="/operator?category=Electricity&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/bulb.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Electricity Bill</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/broadband.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Broadband Postpaid</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/booking.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Book a Cylinder</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/telephone.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Landline Postpaid</h1>
            </Link>
          </div>
        </div>
        <div>
          <h2 className="text-[#681667] font-bold mb-2 text-[16px]">Financial Services & Taxes</h2>
          <div className="w-full grid grid-cols-4 gap-2 select-none rounded">
            <Link to="/ComingSoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/insurance.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Insurance</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/tax.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Municipal Taxes</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/municipal-house.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Municipal Services</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/deposite.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Recurring Deposit</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/loan.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold  leading-[1.1] text-center">Loan Payment</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/ncmc.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Rental Payment</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/credit-card.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold] leading-[1.1] text-center">Credit Card</h1>
            </Link>
          </div>
        </div>
        <div>
          <h2 className="text-[#681667] font-bold mb-2 text-[16px]">More Services</h2>
          <div className="w-full grid grid-cols-4 gap-2 select-none rounded">
            <Link className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/teamwork.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Club & Association</h1>
            </Link>
            <Link to="/ComingSoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/hospital.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold">Hospital</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/education.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Education Fee</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/health-insurance.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Health Insurance</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/pathology.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Hospital & Pathology</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/society.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Housing Society</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/life-insurance.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Life Insurance</h1>
            </Link>
            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/donate.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold leading-[1.1] text-center">Donation</h1>
            </Link>

            <Link to="/comingsoon" className="bg-white gap-2 p-1 h-[72px] lg:h-[120px] flex flex-col items-center justify-center rounded">
              <img src="/categories/subscribe.png" className="h-[28px]" alt="logo" />
              <h1 className="text-[12px] font-semibold">Subscription</h1>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeeMore;

import React from "react";
import { FaArrowLeft } from "react-icons/fa6";

import { MdEmail } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

const ComingSoon = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-purple-600 to-blue-500 text-white">
      <button onClick={handleBack} className="fixed top-3 left-3.5">
        <FaArrowLeft size={25} />
      </button>
      <h1 className="text-4xl md:text-6xl font-bold mb-4">Coming Soon</h1>
      <p className="text-lg md:text-xl text-center mb-8 max-w-md">We’re working hard to bring you something amazing! Stay tuned and check back soon.</p>
    </div>
  );
};

export default ComingSoon;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck, faCircleExclamation, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import Error from "../Components/Error";
import Loading from "../Components/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function TransactionStatus() {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState(false);
  const [order_info, setOrderInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get("order_id");

  useEffect(() => {
    if (!orderId) {
      setError(true);
      setIsLoading(false);
    } else {
      fetchdata();
    }
  }, [orderId]);

  async function fetchdata() {
    try {
      const response = await fetch(`${base_url}app/order_info.php?key=${key}&order_id=${orderId}`);
      const data = await response.json();
      if (data.status === "Success") {
        setOrderInfo(data.data1);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleSubmit = async () => {
    if (!reason.trim() || !description.trim()) {
      toast.error("Please fill out all the fields.");
      return;
    }

    try {
      const response = await fetch(`${base_url}app/complaint.php?key=${key}&order_id=${orderId}&reason=${reason}&message=${message}`);

      const result = await response.json();

      if (result.status === "Success") {
        toast.success("Complaint raised successfully.");
        setIsModalOpen(false);
      } else {
        toast.error(result.message || "Failed to raise a complaint.");
      }
    } catch (error) {
      toast.error("An error occurred while raising the complaint.");
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : error ? (
        <Error />
      ) : (
        <div className="min-h-screen flex flex-col items-center justify-center bg-[#681667] p-4">
          <Link to="/reportcard" className="w-full max-w-md mb-4 flex items-center text-white">
            <FontAwesomeIcon icon={faArrowLeft} className="text-2xl" />
          </Link>
          <div className="bg-white rounded-full p-4 py-5 mb-4">
            {order_info.status === "1" ? (
              <div className="bg-white rounded-full px-2">
                <FontAwesomeIcon icon={faCheck} className="text-green-500 text-7xl" />
              </div>
            ) : order_info.status === "0" ? (
              <div className="bg-white rounded-full p-2 py-6 flex items-center justify-center">
                <div className="flex justify-center items-center gap-2">
                  <div className="w-[13px] h-[13px] rounded-full bg-yellow-500"></div>
                  <div className="w-[13px] h-[13px] rounded-full bg-yellow-500"></div>
                  <div className="w-[13px] h-[13px] rounded-full bg-yellow-500"></div>
                </div>
              </div>
            ) : (
              <div className="bg-white rounded-full px-3 flex items-center justify-center">
                <FontAwesomeIcon icon={faTimes} className="text-red-500 text-6xl" />
              </div>
            )}
          </div>
          <h2 className="text-white font-semibold text-lg mb-6">{order_info.status === "1" ? "Transaction Successful" : order_info.status === "0" ? "Transaction Processing" : "Transaction Failure"}</h2>
          <div className="w-full max-w-md bg-white rounded-lg shadow-md p-4 mb-4">
            <div className="flex justify-between mb-1 pb-1 border-b">
              <span className="text-gray-500">Biller Name</span>
              <span className="text-gray-900">{order_info.biller_name}</span>
            </div>
            <div className="flex justify-between mb-1 pb-1 border-b">
              <span className="text-gray-500">Date & Time</span>
              <span className="text-gray-900">{order_info.createAt}</span>
            </div>
            <div className="flex justify-between mb-1 pb-1 border-b">
              <span className="text-gray-500">Order ID</span>
              <span className="text-gray-900">{order_info.order_id}</span>
            </div>
            <div className="flex justify-between mb-1 pb-1 border-b">
              <span className="text-gray-500">Consumer no.</span>
              <span className="text-gray-900">{order_info.number}</span>
            </div>
            <div className="flex justify-between mb-1 pb-1 border-b">
              <span className="text-gray-500">Customer Name</span>
              <span className="text-gray-900">{order_info.user_name}</span>
            </div>
            <div className="flex justify-between mb-1 pb-1 border-b">
              <span className="text-gray-500">B-Connect Txn ID</span>
              <span className="text-gray-900">{order_info.txn_id}</span>
            </div>
            <div className="flex justify-between mb-1 pb-1 border-b">
              <span className="text-gray-500">Amount (₹)</span>
              <span className="text-gray-900">{order_info.amount}</span>
            </div>
            <div className="flex justify-between mb-1 pb-1 border-b">
              <span className="text-gray-500">Discount (₹)</span>
              <span className="text-gray-900">{order_info.discount}</span>
            </div>
            <div className="flex justify-between mb-1 pb-1 border-b">
              <span className="text-gray-500">Convenience Fee (₹)</span>
              <span className="text-gray-900">{order_info.convenience}</span>
            </div>
          </div>

          <div className="w-full max-w-md bg-gray-100 rounded-lg p-4 mb-4 text-gray-700 text-[15px]">
            Please verify the number and operator before filing a complaint. For successful transactions with incorrect operator, number, or plan details, reversals or refunds are not possible. For more information, please refer to our refund policy.
          </div>

          <button onClick={handleModalToggle} className="w-full max-w-md flex items-center justify-center gap-2 bg-gray-200 text-[#681667]font-semibold py-2 rounded-full hover:bg-gray-300">
            <FontAwesomeIcon icon={faCircleExclamation} className="text-xl" color="#681667" />
            <span>Raise Complaint</span>
          </button>
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 px-2">
              <div className="bg-white rounded-lg p-6 w-full max-w-md">
                <h3 className="text-lg font-semibold mb-4">Raise a Complaint</h3>
                <div className="mb-4">
                  <label className="block text-gray-700">Order id</label>
                  <input type="text" value={orderId} onChange={(e) => setReason(e.target.value)} className="w-full px-4 py-2 border-2 rounded-md focus:outline-none border-[#681667]" placeholder="Enter reason" />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Reason</label>
                  <select value={reason} onChange={(e) => setReason(e.target.value)} className="w-full px-4 py-2 border-2 rounded-md focus:outline-none border-[#681667] bg-white">
                    <option value="">Choose complaint reason</option>
                    <option value="Transaction Successful account not updated">Transaction Successful, account not updated</option>
                    <option value="Amount deducted, biller account credited but transaction ID not received">Amount deducted, biller account credited but transaction ID not received</option>
                    <option value="Amount deducted, biller account not credited & transaction ID not received">Amount deducted, biller account not credited & transaction ID not received</option>
                    <option value="Amount deducted multiple times">Amount deducted multiple times</option>
                    <option value="Double payment updated">Double payment updated</option>
                    <option value="Erroneously paid wrong account">Erroneously paid wrong account</option>
                    <option value="Others provide details in description">Others, provide details in description</option>
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700">Description</label>
                  <textarea value={description} onChange={(e) => setDescription(e.target.value)} className="w-full px-4 py-2 border-2 rounded-md focus:outline-none border-[#681667]" placeholder="Enter description"></textarea>
                </div>
                <div className="flex justify-end gap-2">
                  <button onClick={handleModalToggle} className="px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300 border-2 border-[#681667]">
                    Cancel
                  </button>
                  <button onClick={handleSubmit} className="px-4 py-2 bg-[#681667] text-white rounded-lg hover:bg-teal-600">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <ToastContainer />
    </>
  );
}

export default TransactionStatus;

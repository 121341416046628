import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";

const User = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");

  const [isVisible, setIsVisible] = useState(true);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleBack = () => {
    setIsVisible(false);
    setTimeout(() => {
      navigate(-1);
    }, 300);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${base_url}app/profile_report.php?key=${key}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        const data = await response.json();
        setUserData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [base_url, key]);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div initial={{ y: 700 }} animate={{ y: 0 }} exit={{ y: 800 }} transition={{ ease: "easeOut", duration: 0.5 }}>
          <div className="h-screen bg-slate-200">
            <div className="flex items-center justify-between px-3 py-2 border-b-2">
              <h1 className="text-[20px] font-semibold select-none">Balance</h1>
              <RxCross2 onClick={handleBack} size={28} />
            </div>

            <>
              {loading && <Loading />}
              {!loading && error && <p className="p-3 text-center text-red-500">{error}</p>}
              {!loading && !error && userData && (
                <>
                  <div className="p-3 flex items-center justify-between border-b-2 border-white py-4">
                    <div>
                      <h1 className="text-[13px] opacity-60">Added Balance</h1>
                      <div className="flex items-center gap-2 pt-1">
                        <img src="/wallet.png" className="h-[28px]" alt="logo" />
                        <p className="font-bold">₹{userData.data1 || "0.00"}</p>
                      </div>
                    </div>
                    <Link to="/AddMoney" className="bg-[#681667] text-white px-3 py-1 rounded">
                      Add Money
                    </Link>
                  </div>

                  <div className="p-3 border-b-2 border-white select-none">
                    <h1 className="text-[13px] opacity-60">Rewards</h1>
                    <p className="font-semibold mt-1 opacity-60">₹{userData.data2 || "0.00"}</p>
                  </div>

                  <div className="p-3 flex items-center justify-between border-b-2 border-white py-4 select-none">
                    <div>
                      <h1 className="text-[13px] opacity-60">Total Sell</h1>
                      <div className="flex items-center gap-2 pt-1">
                        <p className="font-bold opacity-60">₹{userData.data3 || "0.00"}</p>
                      </div>
                    </div>
                    <div>
                      <h1 className="text-[13px] opacity-60">Total Earn</h1>
                      <p className="font-semibold mt-1 opacity-60">₹{userData.data4 || "0.00"}</p>
                    </div>
                  </div>

                  <div className="p-3 flex items-center justify-between border-b-2 border-white py-4 select-none">
                    <div>
                      <h1 className="text-[13px] opacity-60">Distributor Program</h1>
                      <h1 className="font-bold">Earn unlimited cashback.</h1>
                    </div>
                    <a href="https://api.whatsapp.com/send?phone=8403957036" className="bg-[#681667] text-white px-3 py-1 rounded">
                      Join Now
                    </a>
                  </div>
                </>
              )}
            </>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default User;

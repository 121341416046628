import React, { useState, useEffect } from "react";
import { FaUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const Header = () => {
  const [userName, setUserName] = useState(localStorage.getItem("name") || "No Name");
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");

  useEffect(() => {
    if (!localStorage.getItem("name")) {
      const fetchUserName = async () => {
        try {
          const response = await fetch(`${base_url}app/get_profile.php?key=${key}`);
          if (response.ok) {
            const data = await response.json();
            const fetchedName = data.name || "User";
            setUserName(fetchedName);
            localStorage.setItem("name", fetchedName);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUserName();
    }
  }, [base_url, key]);

  return (
    <header className="flex items-center justify-between px-[8px] pr-[10px] py-3 bg-white shadow fixed top-0 z-[50] w-full select-none">
      <div>
        <p className="text-[9px] font-bold">Welcome Back!</p>
        <h1 className="font-bold">Hi, {userName}</h1>
      </div>
      <Link to="/User">
        <FaUserCircle size={28} />
      </Link>
    </header>
  );
};

export default Header;

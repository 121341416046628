import React, { useState } from "react";
import Header from "../Components/Header";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { IoWarningOutline } from "react-icons/io5";

const Setting = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");
  const [showModal, setShowModal] = useState(false);
  const handleLogout = () => {
    // Clear session data
    localStorage.clear();
    sessionStorage.clear();
    document.cookie = "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.href = "/"; // Redirect to login page
  };
  return (
    <div className="bg-slate-200 select-none pb-5 pt-[62px] min-h-screen">
      <Header />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-2 lg:gap-3 md:px-2 lg:px-3 md:mt-2 lg:mt-3">
        <Link to="/profile" className="flex items-center gap-4 bg-white mt-2 md:mt-0 py-4 md:py-6 px-3 md:rounded">
          <img src="/settings/user.png" alt="logo" className="h-[32px] w-[32px]" />
          <div className="opacity-80">
            <h1 className="text-[12px] font-bold leading-[1.5]">My Profile</h1>
            <p className="text-[11px] font-semibold leading-[1.4]">Easily manage and update your personal information for a more tailored experience on our platform.</p>
          </div>
        </Link>
        <div className="flex items-center gap-4 bg-white mt-1 md:mt-0 py-4 md:py-6 px-3 md:rounded">
          <img src="/settings/privacy-policy.png" alt="logo" className="h-[32px] w-[32px]" />
          <div className="opacity-80">
            <h1 className="text-[12px] font-bold leading-[1.5]">Privacy Policy</h1>
            <p className="text-[11px] font-semibold leading-[1.4]">Learn more about our stringent privacy measures and how we protect your personal information.</p>
          </div>
        </div>
        <div className="flex items-center gap-4 bg-white mt-1 md:mt-0 py-4 md:py-6 px-3 md:rounded">
          <img src="/settings/terms-and-conditions.png" alt="logo" className="h-[32px] w-[32px]" />
          <div className="opacity-80">
            <h1 className="text-[12px] font-bold leading-[1.5]">Terms & Service</h1>
            <p className="text-[11px] font-semibold leading-[1.4]">Review our terms and services to understand the guidelines and agreement of our services.</p>
          </div>
        </div>
        <div className="flex items-center gap-4 bg-white mt-1 md:mt-0 py-4 md:py-6 px-3 md:rounded">
          <img src="/settings/refund.png" alt="logo" className="h-[32px] w-[32px]" />
          <div className="opacity-80">
            <h1 className="text-[12px] font-bold leading-[1.5]">Refund policy</h1>
            <p className="text-[11px] font-semibold leading-[1.4]">Please read our refund policy to understand the conditions and procedures for eligibility for a refund.</p>
          </div>
        </div>
        <a href="mailto:everythinglearn89@gmail.com" className="flex items-center gap-4 bg-white mt-1 md:mt-0 py-4 md:py-6 px-3 md:rounded">
          <img src="/settings/write.png" alt="logo" className="h-[32px] w-[32px]" />
          <div className="opacity-80">
            <h1 className="text-[12px] font-bold leading-[1.5]">Write to us</h1>
            <p className="text-[11px] font-semibold leading-[1.4]">Feel free to reach out to us with any inquiries, feedback or assistance you may need: we're here to help.</p>
          </div>
        </a>
        <a href="https://play.google.com/store/apps/details?id=recharge.my.phone" target="_blank" rel="noopener noreferrer">
          <div className="flex items-center gap-4 bg-white mt-1 md:mt-0 py-4 md:py-6 px-3 md:rounded cursor-pointer">
            <img src="/settings/star.png" alt="logo" className="h-[32px] w-[32px]" />
            <div className="opacity-80">
              <h1 className="text-[12px] font-bold leading-[1.5]">Rate our service</h1>
              <p className="text-[11px] font-semibold leading-[1.4]">Share your valuable feedback and experiences with our service to help us improve and serve you better.</p>
            </div>
          </div>
        </a>
        <a href="https://wa.me/918403957036" target="_blank" rel="noopener noreferrer">
          <div className="flex items-center gap-4 bg-white mt-1 md:mt-0 py-4 md:py-6 px-3 md:rounded cursor-pointer">
            <img src="/settings/operator.png" alt="logo" className="h-[32px] w-[32px]" />
            <div className="opacity-80">
              <h1 className="text-[12px] font-bold leading-[1.5]">Contact us</h1>
              <p className="text-[11px] font-semibold leading-[1.4]">Access our dedicated support team for prompt assistance with any questions, concerns or technical issues.</p>
            </div>
          </div>
        </a>
        <button className="flex items-center gap-4 bg-white mt-1 md:mt-0 py-4 md:py-6 px-3 md:rounded w-full" onClick={() => setShowModal(true)}>
          <img src="/settings/check-out.png" alt="logo" className="h-[32px] w-[32px]" />
          <div className="opacity-80">
            <h1 className="text-[12px] font-bold leading-[1.5] text-start">Logout</h1>
            <p className="text-[11px] font-semibold leading-[1.4] text-start">Logout securely to protect your account and ensure the confidentiality of your personal information.</p>
          </div>
        </button>
      </div>
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white w-80 rounded-lg shadow-lg p-4">
            <div className="flex items-center">
              <img src="/categories/warning.png" alt="Warning" className="h-5 w-5 mr-2" />
              <h2 className="text-lg font-bold">Logout</h2>
            </div>
            <p className="mt-4 text-[16px] text-gray-700">Do you really want to logout?</p>
            <div className="flex justify-end mt-6">
              <button className="px-3 md:rounded py-2 text-[#681667] rounded-md mr-2" onClick={() => setShowModal(false)}>
                Cancel
              </button>
              <button className="px-3 md:rounded py-2 text-[#681667] rounded-md" onClick={handleLogout}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col items-center justify-center py-3 opacity-60 lg:mt-[50px]">
        <h1 className="text-[30px] font-bold">Recharge App</h1>
        <h2 className="text-[15px] font-semibold">Mobile Recharge App</h2>
        <div className="flex gap-1 items-center">
          <h3 className="text-[15px] font-semibold">Made with</h3>
          <img src="/settings/love.png" alt="logo" className="h-[15px] w-[15px] bg-slate-200" />
          <h3 className="text-[15px] font-semibold">in India</h3>
        </div>
      </div>
    </div>
  );
};

export default Setting;

import React, { useEffect, useRef, useState } from "react";
import "../App.css";
import { FaUserCircle } from "react-icons/fa";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { FaRupeeSign, FaPlus } from "react-icons/fa";
import { IoIosPhonePortrait, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Header from "../Components/Header";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { FiMessageSquare } from "react-icons/fi";
import { CiMobile3 } from "react-icons/ci";
import { CiMobile4 } from "react-icons/ci";
import { BsPlayBtn } from "react-icons/bs";
import { FiTv } from "react-icons/fi";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { MdLiveTv } from "react-icons/md";
import { FaRegHospital } from "react-icons/fa6";
import { LiaHandsSolid } from "react-icons/lia";
import { BiSolidReport } from "react-icons/bi";

function Home() {
  const navigate = useNavigate();
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");
  const [showMore, setShowMore] = useState(false);
  const [walletBalance, setWalletBalance] = useState("0.00");
  const [referCode, setReferCode] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${base_url}app/balance.php?key=${key}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        const data = await response.json();
        setWalletBalance(data.data1 || "0.00");
        setReferCode(data.data2 || "N/A");
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [base_url, key]);

  const handleToggle = () => {
    setShowMore((prev) => !prev);
  };
  return (
    <div className="pt-[62px] md:pb-[140px]">
      <Header />
      <div className="carousel">
        <div className="splide">
          <Splide
            options={{
              pagination: true,
              arrows: false,
              drag: true,
              autoplay: false,
              interval: 3000,
              speed: 1000,
            }}
          >
            <SplideSlide>
              <a href="https://api.whatsapp.com/send?phone=918403957036" target="_blank" rel="noopener noreferrer">
                <img src="/main.png" alt="carousel" className=" h-full md:h-[300px] w-full " />
              </a>
            </SplideSlide>
            <SplideSlide>
              <a href="https://api.whatsapp.com/send?phone=918403957036" target="_blank" rel="noopener noreferrer">
                <img src="/main.png" alt="carousel" className=" h-full md:h-[300px] w-full " />
              </a>
            </SplideSlide>
          </Splide>
        </div>
      </div>
      <div className=" w-full py-2 pt-3 px-2 select-none">
        <div className="h-full w-full bg-[#681667] rounded-lg flex items-center justify-between ps-2 py-5">
          <div className="flex items-center justify-between text-white gap-2">
            <MdOutlineAccountBalanceWallet size={45} />
            <div>
              <h3 className="text-[13px] font-bold">Wallet Balance</h3>
              <div className="flex items-center justify-center">
                <FaRupeeSign />
                <h1 className="font-bold text-[18px]">{walletBalance}</h1>
              </div>
            </div>
          </div>
          <Link to="/AddMoney" className="flex items-center gap-2 bg-white px-[8px] py-[6px] addMoney">
            <FaPlus className="opacity-70" />
            <h1 className="font-semibold">Add Money</h1>
          </Link>
        </div>
      </div>
      <div className="w-full bg-white p-2 select-none">
        <a href="https://api.whatsapp.com/send?phone=918403957036" className="h-[45px] w-full bg-green-100 rounded-md  px-2 text-green-600 gap-4 py-4 flex items-center justify-center" target="_blank">
          <FiMessageSquare />
          <h1>Need help? Let's connect with support</h1>
        </a>
      </div>
      <div className="bg-slate-200 w-full p-2 grid grid-cols-4 gap-2 px-2 select-none">
        <Link to="/Prepaid" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <CiMobile3 size={25} />
          <h1 className="text-[12px] font-semibold ">Prepaid</h1>
        </Link>
        <Link to="/operator?category=Postpaid&sub_category=Non BBPS" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <CiMobile4 size={25} />
          <h1 className="text-[12px] font-semibold ">Postpaid</h1>
        </Link>
        <Link to="/operator?category=Dth&sub_category=Non BBPS" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <FiTv size={22} />
          <h1 className="text-[12px] font-semibold">DTH</h1>
        </Link>
        <Link to="/operator?category=Redeem code&sub_category=Non BBPS" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <BsPlayBtn size={22} />
          <h1 className="text-[12px] font-semibold">Redeem Code</h1>
        </Link>
        <Link to="/operator?category=Electricity&sub_category=BBPS" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <img src="/categories/bulb.png" className="h-[28px]" alt="logo" />
          <h1 className="text-[12px] font-semibold">Electricity</h1>
        </Link>
        <Link to="/paymentsuccess" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <img src="/categories/fastag.png" className="h-[28px]" alt="logo" />
          <h1 className="text-[12px] font-semibold">Fastag</h1>
        </Link>
        <Link to="/dayBook" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <img src="/categories/gas.png" className="h-[28px]" alt="logo" />
          <h1 className="text-[12px] font-semibold">LPG Gas</h1>
        </Link>

        <Link to="/seemore" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <img src="/categories/more.gif" className="h-[28px]" alt="logo" />
          <h1 className="text-[12px] font-semibold">See more</h1>
        </Link>
      </div>

      <div className="w-full bg-slate-200 p-2 pt-3">
        <div className="h-full w-full bg-[#681667] rounded-md flex items-center justify-between px-2 text-white gap-4 py-4">
          <div>
            <h1 className="text-[16px] font-bold select-none">Refer & Earn</h1>
            <h2 className="text-[10px] select-none">Share Recharge Save with your friends and get up-to 20, when they add 199 or more.</h2>
            <h1 className="text-[16px] font-bold select-none">Refer code: {referCode}</h1>
          </div>
          <a href={`https://api.whatsapp.com/send?text=Use my referral code ${referCode} to get rewards on Recharge Save! Download the app here: https://rechargesave.in/share.php?code=${referCode}`} target="_blank" rel="noopener noreferrer" className="bg-white p-3 rounded">
            <img src="/share.png" alt="logo" className="h-[18px] w-[22px]" />
          </a>
        </div>
      </div>
      <div className="w-full bg-slate-200 p-2  grid grid-cols-5 pb-2 pt-3 gap-1 fixed bottom-0">
        <Link to="/reportcard" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <img src="/categories/report.png" className="h-[28px]" alt="logo" />
          <h1 className="text-[12px] font-semibold ">Report</h1>
        </Link>
        <Link to="/Margin" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <img src="/categories/profit-margin.png" className="h-[28px]" alt="logo" />
          <h1 className="text-[12px] font-semibold">Margin</h1>
        </Link>
        <Link to="/profile" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <img src="/categories/user.png" className="h-[28px]" alt="logo" />
          <h1 className="text-[12px] font-semibold">Profile</h1>
        </Link>
        <Link to="/dayBook" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <img src="/categories/diary.png" className="h-[28px]" alt="logo" />
          <h1 className="text-[12px] font-semibold">Day Book</h1>
        </Link>
        <Link to="/Setting" className="bg-white gap-2 p-1 h-[72px] md:h-[120px] flex flex-col items-center justify-center rounded">
          <img src="/categories/settings.png" className="h-[28px]" alt="logo" />
          <h1 className="text-[12px] font-semibold">Setting</h1>
        </Link>
      </div>
    </div>
  );
}

export default Home;

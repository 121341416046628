import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const DayBook = () => {
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setCurrentDate(formattedDate);
  }, []);

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  const data = [{ id: "Airtel", name: 10000, age: 10000, email: 10000, city: 10000 }];
  return (
    <div className="bg-slate-200 min-h-screen">
      <div className="h-[55px] w-full bg-[#681667] flex items-center px-4 gap-5 text-white">
        <button onClick={handleBack}>
          <FaArrowLeft size={20} />
        </button>
        <h1 className="font-bold text-[17px]">Day Book</h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 px-3 md:px-4 gap-2 md:gap-4 pt-5">
        <div>
          <label className="block text-sm font-medium text-gray-700">From Date</label>
          <input
            type="date"
            value={currentDate} // Set current date as default
            className="w-full py-3 border-2 border-[#681667] rounded-md bg-white outline-none px-2"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">To Date</label>
          <input
            type="date"
            value={currentDate} // Set current date as default
            className="w-full py-3 border-2 border-[#681667] rounded-md bg-white outline-none px-2"
          />
        </div>
        <div className="w-full">
          <button className="bg-[#681667] border-2 border-[#681667] text-white py-3 rounded-md mt-2 select-none w-full md:translate-y-[12px]">Search</button>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 w-full px-3 md:px-4 mt-5 select-none">
        <div className="bg-white rounded-md flex flex-col items-center justify-center gap- px-2 h-[100px] lg:h-[110px] py-5">
          <h1 className="text-[20px] font-bold text-[#681667]">0.00</h1>
          <h1>Add Money</h1>
        </div>
        <div className="bg-white rounded-md flex flex-col items-center justify-center gap- px-2 h-[100px] lg:h-[110px] py-5">
          <h1 className="text-[20px] font-bold text-[#681667]">0.00</h1>
          <h1>Total Recharge</h1>
        </div>
        <div className="bg-white rounded-md flex flex-col items-center justify-center gap- px-2 h-[100px] lg:h-[110px] py-5">
          <h1 className="text-[20px] font-bold text-[#681667]">0.00</h1>
          <h1>Total Billpay</h1>
        </div>
        <div className="bg-white rounded-md flex flex-col items-center justify-center gap- px-2 h-[100px] lg:h-[110px] py-5">
          <h1 className="text-[20px] font-bold text-[#681667]">0.00</h1>
          <h1>Total Earn</h1>
        </div>
        <div className="bg-white rounded-md flex flex-col items-center justify-center gap- px-2 h-[100px] lg:h-[110px] py-5">
          <h1 className="text-[20px] font-bold text-[#681667]">0.00</h1>
          <h1>Extra Bonus</h1>
        </div>
        <div className="bg-white rounded-md flex flex-col items-center justify-center gap- px-2 h-[100px] lg:h-[110px] py-5">
          <h1 className="text-[20px] font-bold text-[#681667]">0.00</h1>
          <h1>Debit Money</h1>
        </div>
        <div className="bg-white rounded-md flex flex-col items-center justify-center gap- px-2 h-[100px] lg:h-[110px] py-5">
          <h1 className="text-[20px] font-bold text-[#681667]">0.00</h1>
          <h1>Difference</h1>
        </div>
      </div>
      <div className="overflow-x-auto my-5 mx-3 pb-5 select-none">
        <table className="min-w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr className="bg-green-600 text-white ">
              <th className="px-4 py-2 text-left text-sm font-semibold border border-gray-300">Operator</th>
              <th className="px-4 py-2 text-left text-sm font-semibold border border-gray-300">Success</th>
              <th className="px-4 py-2 text-left text-sm font-semibold border border-gray-300">Failed</th>
              <th className="px-4 py-2 text-left text-sm font-semibold border border-gray-300">Pending</th>
              <th className="px-4 py-2 text-left text-sm font-semibold border border-gray-300">Profit</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id} className="bg-white">
                <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">{item.id}</td>
                <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">{item.name}</td>
                <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">{item.age}</td>
                <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">{item.email}</td>
                <td className="px-4 py-2 text-sm text-gray-700 border border-gray-300">{item.city}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DayBook;

import React from "react";

const ProcessingActivity = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-blue-500 text-white">
      {/* Animated Spinner */}
      <div className="w-16 h-16 border-4 border-white border-t-transparent rounded-full animate-spin"></div>
      {/* Text Content */}
      <div className="mt-6 text-center">
        <h1 className="text-xl font-semibold">Processing...</h1>
        <p className="mt-2 text-sm">Hold on while we process the request details...</p>
      </div>
    </div>
  );
};

export default ProcessingActivity;

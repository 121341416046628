import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Home from "./pages/Home";
import AddMoney from "./pages/AddMoney";
import Layout from "./pages/components/Layout";
import Prepaid from "./pages/Prepaid";
import Setting from "./pages/Setting";
import Profile from "./pages/Profile";
import Margin from "./pages/Margin";
import User from "./pages/User";
import GooglePlay from "./pages/GooglePlay";
import DthRecharge from "./pages/DthRecharge";
import ReportCard from "./pages/ReportCard";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ComingSoon from "./pages/ComingSoon";
import TransactionStatus from "./pages/TransactionStatus";
import RechargePlans from "./pages/RechargePlans";
import OperatorBill from "./pages/Operator";
import OperatorForm from "./pages/OperatorForm";
import PaymentSuccess from "./pages/PaymentSuccess";
import Operator from "./pages/Operator";
import BillFetch from "./pages/BillFetch";
import SeeMore from "./pages/SeeMore";
import SelectOperator from "./pages/SelectOperator";
import DayBook from "./pages/DayBook";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="addmoney" element={<AddMoney />} />
        <Route path="signup" element={<Signup />} />
        <Route path="prepaid" element={<Prepaid />} />
        <Route path="operator" element={<Operator />} />
        <Route path="operatorbill" element={<OperatorBill />} />
        <Route path="operatorform" element={<OperatorForm />} />
        <Route path="comingsoon" element={<ComingSoon />} />
        <Route path="setting" element={<Setting />} />
        <Route path="paymentsuccess" element={<PaymentSuccess />} />
        <Route path="profile" element={<Profile />} />
        <Route path="margin" element={<Margin />} />
        <Route path="reportcard" element={<ReportCard />} />
        <Route path="rechargeplans" element={<RechargePlans />} />
        <Route path="billfetch" element={<BillFetch />} />
        <Route path="seemore" element={<SeeMore />} />
        <Route path="selectoperator" element={<SelectOperator />} />
        <Route path="transaction_status" element={<TransactionStatus />} />
        <Route path="dayBook" element={<DayBook />} />
        <Route path="user" element={<User />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

reportWebVitals();

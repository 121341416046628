import React, { useState, useEffect } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Loading from "../Components/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function OperatorForm() {
  const base_url = process.env.REACT_APP_BASE_URL;
  const key = localStorage.getItem("key");

  const [searchParams] = useSearchParams();
  const operator_id = searchParams.get("operator_id");

  const [formData, setFormData] = useState({});
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [billInfo, setBillInfo] = useState(null);
  const [operatorIcon, setOperatorIcon] = useState("");
  const navigate = useNavigate();

  const handleBack = () => navigate(-1);

  useEffect(() => {
    const fetchOperatorData = async () => {
      try {
        const response = await fetch(`${base_url}app/operator_input_field.php?key=${key}&operator_id=${operator_id}`);
        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }
        const data = await response.json();
        if (data.status === "Success") {
          const { nparam, ...rest } = data.data1;

          // Prepare fields for form generation
          const dynamicFields = [];
          for (let i = 1; i <= parseInt(nparam, 10); i++) {
            dynamicFields.push({
              name: `param${i}`,
              label: rest[`paramName_${i}`],
              placeholder: rest[`paramHint_${i}`],
              type: rest[`paramType_${i}`] === "1" ? "number" : rest[`paramType_${i}`] === "2" ? "text" : "select",
              required: rest[`paramIsOptional_${i}`] === "0",
              regex: rest[`paramRegEx_${i}`] || null,
              minLength: rest[`paramMinL_${i}`] || 0,
              maxLength: rest[`paramMaxL_${i}`] || 0,
            });
          }

          setFields(dynamicFields);
          setBillInfo(data.data1);
          setOperatorIcon(data.data1.operator_logo);
        } else {
          setError(data.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (operator_id) fetchOperatorData();
  }, [base_url, key, operator_id]);

  const handleInputChange = (e, field) => {
    setFormData({ ...formData, [field.name]: e.target.value });
  };

  const validateField = (value, field) => {
    if (field.required && !value) return `${field.label} is required`;
    if (field.regex && !new RegExp(field.regex).test(value)) return `${field.label} does not match the required format`;
    if (field.minLength > 0 && value.length < field.minLength) return `${field.label} must be at least ${field.minLength} characters`;
    if (field.maxLength > 0 && value.length > field.maxLength) return `${field.label} must be at most ${field.maxLength} characters`;
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields
    const errors = fields.map((field) => validateField(formData[field.name], field));
    const hasErrors = errors.filter((error) => error !== null).length > 0;
    if (hasErrors) {
      setError(errors.find((error) => error !== null));
      toast.error("Enter a valid number");
      return;
    }

    // Proceed with form submission
    navigate("/billfetch", { state: { formData, operator_id } });
    // Add API call logic here
  };

  if (loading) return <Loading />;
  // if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="text-white bg-slate-200 h-screen">
      <div className="bg-[#681667] p-4 pb-9">
        <header className="flex items-center gap-4">
          <button onClick={handleBack}>
            <FaArrowLeft size={20} />
          </button>
          <button className="text-[20px] font-semibold">Form Fill</button>
        </header>

        <div className="mt-4 flex items-center justify-between">
          <div className="space-y-1">
            <h2 className="text-xl font-bold">{billInfo?.biller_name}</h2>
            <p className="text-sm">Once a payment is successfully processed and added to your account, it cannot be reversed.</p>
          </div>
          <img src={base_url + billInfo.operator_logo} className="h-[50px] rounded-full bg-white" />
        </div>
      </div>

      <form onSubmit={handleSubmit} className="px-4 py-5 bg-slate-200">
        {fields.map((field, index) => (
          <div key={index} className="mb-4">
            <input type={field.type} name={field.name} id={field.name} placeholder={field.placeholder} required={field.required} className="w-full px-4 py-3 text-black bg-white rounded-md border-2 border-[#681667] outline-none" onChange={(e) => handleInputChange(e, field)} />
          </div>
        ))}
        <button type="submit" className="bg-[#681667] text-white w-full py-3 rounded-md">
          Continue
        </button>
      </form>
      <ToastContainer />
    </div>
  );
}

export default OperatorForm;
